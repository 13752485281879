export const DummyDataNotifications = [
	{
		title: 'Welcome!',
		text: 'Thanks for joining Lola ERP ',
	},
	{
		title: 'New Update',
		text: 'New update of lola available',
	},
];
