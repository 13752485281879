import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { CreateUser, UpdateUser, User } from 'domain/Users/users.type';

import { baseConfig } from '../../../config/http';

export const usersApi = createApi({
	reducerPath: 'users-api',
	baseQuery: fetchBaseQuery({
		...baseConfig,
	}),
	endpoints: builder => ({
		createUser: builder.mutation({
			query: (body: CreateUser) => ({
				url: `/api/users`,
				method: 'POST',
				body,
			}),
		}),
		// READ
		getUsers: builder.query({
			query: () => ({
				url: `/api/users`,
			}),
		}),
		// SHOW
		getUserById: builder.mutation({
			query: (userId: string) => ({
				url: `/api/users/${userId}`,
			}),
		}),
		// UPDATE
		updateUser: builder.mutation({
			query: (body: UpdateUser) => ({
				url: `/api/users`,
				method: 'PATCH',
				body,
			}),
		}),
		getUsersTable: builder.mutation({
			query: () => ({
				url: `/api/users`,
			}),
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetUserByIdMutation,
	useUpdateUserMutation,
	useCreateUserMutation,
	useGetUsersTableMutation
} = usersApi as any;
