import { AppModules } from 'config/modules';
import { AppRoute } from 'domain/routes/route.type';
import { hasPermissions } from 'utils/permissions';

export const getAppMenu = (permissions: any, enterprise: any) => {
	const getRoutesFromModuleName = (moduleName: string): AppRoute[] | null => {
		try {
			const { routes } = require(`ui/${moduleName}/routes`) as {
				routes: AppRoute[];
			};
			return routes;
		} catch (e) {
			console.error(`Can not load routes from module ${moduleName}`);
			return null;
		}
	};

	const mapAppRoutes = (userPermissions: any, enterprise: any): AppRoute[] => {
		let appRoutes: AppRoute[] = [];
		for (let index = 0; index < AppModules.length; index++) {
			const moduleName = AppModules[index];
			const routes = getRoutesFromModuleName(moduleName);
			if (!routes) continue;

			const routesFromAuthState = routes.filter(
				route =>
					route.isPrivate &&
					route.showInMenu &&
					(!route.permissions ||
						hasPermissions(route.permissions, userPermissions, enterprise))
			);
			appRoutes.push(...routesFromAuthState);
		}
		return appRoutes;
	};
	return mapAppRoutes(permissions, enterprise);
};
