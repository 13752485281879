import {
	CashIcon,
	LibraryIcon,
	LinkIcon,
	OfficeBuildingIcon,
} from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const BudgetsListPage = lazy(() => import('../pages/BudgetsList'));
const EditBudgetsPage = lazy(() => import('../pages/EditBudgets'));
const AddBudgetsPage = lazy(() => import('../pages/AddBudgets'));

export const routes: AppRoute[] = [
	{
		id: pageIds.budgets,
		name: 'Presupuestos',
		path: '/budgets',
		icon: <CashIcon className={'text-light-primary-green w-6 h-6'} />,
		page: BudgetsListPage,
		breadcrumb: ['Budgets'],
		showInMenu: true,
		isPrivate: true,
	},
	{
		id: 'EditBudgets',
		name: 'Edit Budgets',
		path: '/budgets/edit/:id',
		icon: <CashIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditBudgetsPage,
		breadcrumb: ['Budgets', 'Edit Budgets'],
		isPrivate: true,
	},
	{
		id: 'AddBudgets',
		name: 'Add Budgets',
		path: '/budgets/add',
		icon: <CashIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddBudgetsPage,
		breadcrumb: ['Budgets', 'Add Budgets'],
		isPrivate: true,
	},
];
