import { createSlice } from '@reduxjs/toolkit';
import { IEnterprisesSlice } from './type';

export const initialState: IEnterprisesSlice = {
	enterprices: [],
};

export const EnterprisesSlice = createSlice({
	name: 'enterprises',
	initialState,
	reducers: {
		clearAuthState: () => initialState,
		authenticateUser: (state, action) => ({
			...state,
			userIsAuthenticated: true,
			...action.payload,
		}),
	},
});

export default EnterprisesSlice.reducer;
export const { clearAuthState, authenticateUser } = EnterprisesSlice.actions;