export const pageIds = {
	dashboard: 'Dashboard',
	invoices: 'invoices',
	purchases: 'purchases',
	budgets: 'budgets',
	FND: 'FND',
	users: 'users',
	clients: 'clients',
	products: 'products',
	items: 'items',
	impimur: 'impimur',
	proforms: 'proforms',
	paymentsMethods: 'paymentsMethods',
	productsCategories: 'productsCategories',
	drivers: 'drivers',
	floats: 'floats',
	locality: 'locality',
	references: 'references',
	productsPubliApp: 'productsPubliApp',
	incidencesPubliApp: 'incidencesPubliApp',
};
