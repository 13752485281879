import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { selectAuthState } from 'redux/slices/authentication/selectors';
import { selectEnterpriseState } from 'redux/slices/enterprise/selectors';

import { getAppRoutes } from './routesRegister';

export const AppRouter = () => {
	const { userIsAuthenticated, permissions } = useSelector(selectAuthState);
	const enterprise = useSelector(selectEnterpriseState);
	const AppRoutes = useRoutes(
		getAppRoutes(userIsAuthenticated, permissions, enterprise)
	);
	// @TODO
	return <Suspense fallback={<>Cargando ando..</>}>{AppRoutes}</Suspense>;
};
