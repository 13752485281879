import { createSlice } from '@reduxjs/toolkit';
import { IBreadCrumbSlice } from './type';

export const initialState: IBreadCrumbSlice = {
	breadcrumbs: [],
};

export const BreadCrumbSlice = createSlice({
	name: 'breadcrumb',
	initialState,
	reducers: {
		setBreadCrumb: (state, action) => ({ ...state, breadcrumbs: action.payload }),
	},
});

export default BreadCrumbSlice.reducer;
export const { setBreadCrumb } = BreadCrumbSlice.actions;
