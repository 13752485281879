export const hasPermissions = (routePermissions: any, userPermissions: any, enterprise: any) => {
	if (!Object.keys(routePermissions)?.length) {
		return true;
	}
	for (const routePermission of routePermissions) {
		const [module, permission] = routePermission?.split('/');
		if (!enterprise?._id) {
			continue;
		}
		if (!userPermissions.hasOwnProperty(module)) {
			continue;
		}
		const modulePermission = userPermissions[module];
		if (!modulePermission.hasOwnProperty(permission)) {
			continue;
		}
		return userPermissions[module][permission];
	}

	return false;
};
