import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { modulesAPI } from 'redux/apis/modules';
import { usersApi } from 'redux/apis/users';
import { authenticationApi } from '../../redux/apis/authentication';
import { clientsApi } from '../../redux/apis/clients';
import { enterprisesApi } from '../../redux/apis/enterprises';
import { rootReducer } from './rootReducer';

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(
			authenticationApi.middleware,
			clientsApi.middleware,
			enterprisesApi.middleware,
			modulesAPI.middleware,
			usersApi.middleware
		),
});
export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
