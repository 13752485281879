import { FC, PropsWithChildren } from 'react';

interface PropTypes {
	isTrue: boolean;
}
export const RenderIf: FC<PropsWithChildren<PropTypes>> = ({
	children,
	isTrue,
}) => {
	return <>{isTrue ? children : null}</>;
};
