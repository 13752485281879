import { HomeIcon, IdentificationIcon } from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy, Suspense } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const DashboardPage = lazy(() => import('../pages/Dashboard'));

export const routes: AppRoute[] = [
	{
		id: pageIds.dashboard,
		name: 'Dashboard',
		path: '/',
		icon: <HomeIcon className={'text-light-primary-green w-6 h-6'} />,
		page: DashboardPage,
		breadcrumb: ['Dashboard'],
		showInMenu: true,
		isPrivate: true,
	},
];
