import { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/solid';
import { DummyDataNotifications } from './dummyDataNotifications';
import { NotificationsItem } from '../../atoms/Notifications/NotificationsItem';
import { NotificationsIcon } from '../../atoms/Notifications/NotificationIcon';

export const DropdowNotifications: FC = () => {
	return (
		<>
			<Menu as='div' className='relative inline-block text-left'>
				<div className='flex justify-center items-center'>
					<Menu.Button className='relative'>
						<NotificationsIcon hasNewNotification />
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter='transition ease-out duration-100'
					enterFrom='transform opacity-0 scale-95'
					enterTo='transform opacity-100 scale-100'
					leave='transition ease-in duration-75'
					leaveFrom='transform opacity-100 scale-100'
					leaveTo='transform opacity-0 scale-95'
				>
					<Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
						{DummyDataNotifications.map((data, index) => (
							<div key={data.title} className='px-1 py-1'>
								<NotificationsItem title={data.title} text={data.text} />
							</div>
						))}
					</Menu.Items>
				</Transition>
			</Menu>
		</>
	);
};
