import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
//import { Item } from 'redux/slices/items/type';
import { baseConfig } from '../../../config/http';

// We can move endpoint to another files
export const publiappApi = createApi({
	reducerPath: 'publiapp-api',
	baseQuery: fetchBaseQuery({
		...baseConfig,
	}),
	endpoints: builder => ({
		getLocalities: builder.mutation({
			query: () => ({
				url: `/api/publiapp/localities`,
				method: 'GET',
			}),
		}),
		getLocalityById: builder.mutation({
			query: (id: string) => ({
				url: `/api/publiapp/localities/${id}`,
				method: 'GET',
			}),
		}),
		createLocalities: builder.mutation({
			query: (locality: any) => ({
				url: `/api/publiapp/localities`,
				method: 'POST',
				body: { locality },
			}),
		}),
		updateLocality: builder.mutation({
			query: (locality: any) => ({
				url: `/api/publiapp/localities`,
				method: 'PATCH',
				body: { locality },
			}),
		}),
		//References
		getReferences: builder.mutation({
			query: () => ({
				url: `/api/publiapp/references`,
				method: 'GET',
			}),
		}),
		getReferenceById: builder.mutation({
			query: (id: string) => ({
				url: `/api/publiapp/references/${id}`,
				method: 'GET',
			}),
		}),
		createReferences: builder.mutation({
			query: (references: any) => ({
				url: `/api/publiapp/references`,
				method: 'POST',
				body: { references },
			}),
		}),
		updateReference: builder.mutation({
			query: (reference: any) => ({
				url: `/api/publiapp/references`,
				method: 'PATCH',
				body: { reference },
			}),
		}),
		//Products
		getProducts: builder.mutation({
			query: () => ({
				url: `/api/publiapp/products`,
				method: 'GET',
			}),
		}),
		getProductById: builder.mutation({
			query: (id: string) => ({
				url: `/api/publiapp/products/${id}`,
				method: 'GET',
			}),
		}),
		createProduct: builder.mutation({
			query: (product: any) => ({
				url: `/api/publiapp/products`,
				method: 'POST',
				body: { product },
			}),
		}),
		updateProduct: builder.mutation({
			query: (product: any) => ({
				url: `/api/publiapp/products`,
				method: 'PATCH',
				body: { product },
			}),
		}),
		//Incidences
		getIncidences: builder.mutation({
			query: () => ({
				url: `/api/publiapp/incidences`,
				method: 'GET',
			}),
		}),
		solveIncidences: builder.mutation({
			query: (incidence: any) => ({
				url: `/api/publiapp/incidences/solve`,
				method: 'PATCH',
				body: { incidence },
			}),
		}),
	}),
});

export const {
	useGetLocalitiesMutation,
	useGetLocalityByIdMutation,
	useCreateLocalitiesMutation,
	useUpdateLocalityMutation,
	useGetReferencesMutation,
	useGetReferenceByIdMutation,
	useCreateReferencesMutation,
	useUpdateReferenceMutation,
	useGetProductsMutation,
	useGetProductByIdMutation,
	useCreateProductMutation,
	useUpdateProductMutation,
	useGetIncidencesMutation,
	useSolveIncidencesMutation,
} = publiappApi as any;
