import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RecoverPassword } from 'domain/RecoverPassword/recover.type';
import { ResetPassword } from 'domain/ResetPassword/reset.type';
import { baseConfig } from '../../../config/http';
import {
	Credentials,
	VerifyAccount,
} from '../../../ui/Example/domain/credentials/credentials.type';

// We can move endpoint to another files
export const authenticationApi = createApi({
	reducerPath: 'authentication-api',
	baseQuery: fetchBaseQuery({
		...baseConfig,
	}),
	endpoints: builder => ({
		signInUser: builder.mutation({
			query: (credentials: Credentials) => ({
				url: `/papi/signin`,
				method: 'POST',
				body: credentials,
			}),
		}),
		signUpUser: builder.mutation({
			query: (credentials: Credentials) => ({
				url: `/papi/signup`,
				method: 'POST',
				body: credentials,
			}),
		}),
		verifyAccount: builder.mutation({
			query: (credentials: VerifyAccount) => ({
				url: `/papi/account/validate`,
				method: 'POST',
				body: credentials,
			}),
		}),
		sendEmailRecover: builder.mutation({
			query: (data: RecoverPassword) => ({
				url: `/papi/recover-password`,
				method: 'POST',
				body: data,
			}),
		}),
		resetPassword: builder.mutation({
			query: (data: ResetPassword) => ({
				url: `/papi/change-password`,
				method: 'POST',
				body: data,
			}),
		}),
		whoIAm: builder.mutation({
			query: () => ({
				url: `/api/who-i-am`,
				method: 'POST',
			}),
		}),
	}),
});

export const {
	useSignInUserMutation,
	useSignUpUserMutation,
	useVerifyAccountMutation,
	useSendEmailRecoverMutation,
	useResetPasswordMutation,
	useWhoIAmMutation,
} = authenticationApi as any;
