import { createSlice } from '@reduxjs/toolkit';
import { IAuthenticationSlice } from './type';

export const initialState: IAuthenticationSlice = {
	userIsAuthenticated: false,
	permissions: {},
	enterpriseId: undefined,
	isLoading: true,
};

export const AuthenticationSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		clearAuthState: () => initialState,
		authenticateUser: (state, action) => ({
			...state,
			userIsAuthenticated: true,
			...action.payload,
		}),
		updateStatus: (state, action) => {
			console.log(action, state);
			return {
				...state,
				status: action.payload?.status || state.status,
			};
		},
	},
});

export default AuthenticationSlice.reducer;
export const { clearAuthState, authenticateUser, updateStatus } =
	AuthenticationSlice.actions;
