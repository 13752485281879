import {
	LocationMarkerIcon,
	MapIcon,
	NewspaperIcon,
	ExclamationIcon,
} from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const AddLocalityPage = lazy(() => import('../pages/AddLocality'));
const AddReferencesPage = lazy(() => import('../pages/AddRefence'));
const AddProductsPage = lazy(() => import('../pages/AddProduct'));
const ProductListPage = lazy(() => import('../pages/ProductList'));
const EditProductPage = lazy(() => import('../pages/EditProduct'));
const IncidencesListPage = lazy(() => import('../pages/IncidencesList'));

export const routes: AppRoute[] = [
	{
		id: pageIds.locality,
		name: 'Localities',
		path: '/locality',
		icon: <LocationMarkerIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddLocalityPage,
		breadcrumb: ['Locality'],
		isPrivate: true,
		showInMenu: true,
	},
	{
		id: pageIds.references,
		name: 'References',
		path: '/references',
		icon: <NewspaperIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddReferencesPage,
		breadcrumb: ['References'],
		isPrivate: true,
		showInMenu: true,
	},
	{
		id: pageIds.productsPubliApp,
		name: 'Products',
		path: '/ProductsPubli',
		icon: <MapIcon className={'text-light-primary-green w-6 h-6'} />,
		page: ProductListPage,
		breadcrumb: ['ProductsPubli'],
		isPrivate: true,
		showInMenu: true,
	},
	{
		id: pageIds.productsPubliApp,
		name: 'Products',
		path: '/ProductsPubli/create',
		icon: <MapIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddProductsPage,
		breadcrumb: ['ProductsPubli'],
		isPrivate: true,
		showInMenu: false,
	},
	{
		id: pageIds.productsPubliApp,
		name: 'Products',
		path: '/ProductsPubli/edit/:id',
		icon: <MapIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditProductPage,
		breadcrumb: ['ProductsPubli'],
		isPrivate: true,
		showInMenu: false,
	},
	{
		id: pageIds.incidencesPubliApp,
		name: 'Incidences',
		path: '/incidencesPubli',
		icon: <ExclamationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: IncidencesListPage,
		breadcrumb: ['IncidencesPubli'],
		isPrivate: true,
		showInMenu: true,
	},
];
