import { IdentificationIcon } from '@heroicons/react/solid';
import { Permissions } from 'domain/Permissions';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const TestPDFPage = lazy(() => import('../pages/themplate'));

export const routes: AppRoute[] = [
	{
		id: 'PDFview',
		name: 'PDFview',
		path: '/pdfview',
		icon: <IdentificationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: TestPDFPage,
		breadcrumb: ['PDFview'],
		showInMenu: true,
		isPrivate: true,
		permissions: [Permissions.users.read],
	},
];
