import { pageIds } from './pageIds';

export const menuStructure: any = {
	Dashboard: pageIds.dashboard,
	Sales: [
		pageIds.invoices,
		pageIds.budgets,
		pageIds.FND,
		pageIds.impimur,
		pageIds.proforms,
	],
	Purchases: [pageIds.purchases],
	Team: [pageIds.users, pageIds.clients],
	Inventory: [pageIds.products, pageIds.items, pageIds.productsCategories],
	Logistic: [pageIds.drivers, pageIds.floats],
	PubliApp: [
		pageIds.locality,
		pageIds.references,
		pageIds.productsPubliApp,
		pageIds.incidencesPubliApp,
	],
};
