import {
	FC,
	PropsWithChildren,
	useCallback,
	useEffect,
	useLayoutEffect,
	useState,
} from 'react';
import { LoadingAnimated } from 'components/atoms/LoadingAnimated';
import { useDispatch } from 'react-redux';
import { useWhoIAmMutation } from '../../redux/apis/authentication';
import {
	authenticateUser,
	clearAuthState,
} from '../../redux/slices/authentication';
import { useGetCurrentEnterpriseMutation } from '../../redux/apis/enterprises';
import { updateEnterpiseState } from '../../redux/slices/enterprise';

export const AuthenticationProvider: FC<PropsWithChildren<any>> = ({
	children,
}) => {
	const dispatch = useDispatch();
	const [fetchAuthState, { isLoading }] = useWhoIAmMutation();
	const [getEnterprise] = useGetCurrentEnterpriseMutation();
	const [isLoadingAnimation, setIsLoadingAnimation] = useState<boolean>();

	const checkAuthState = useCallback(async () => {
		setIsLoadingAnimation(true);
		const token = localStorage.getItem('token');
		if (!token) {
			setIsLoadingAnimation(false);
			return null;
		}
		const response = await fetchAuthState();
		if (!response?.data) {
			dispatch(clearAuthState());
			setTimeout(async () => {
				setIsLoadingAnimation(false);
			}, 500);
			return;
		}
		dispatch(authenticateUser({ ...response?.data, isLoading: false }));

		getEnterprise().then((response: any) => {
			const { data } = response;
			if (data?.enterprise) {
				dispatch(updateEnterpiseState(data?.enterprise));
			}
		});

		setTimeout(async () => {
			setIsLoadingAnimation(false);
		}, 1200);
	}, []);

	useEffect(() => {
		if (isLoading) {
			// @TODO
			console.log('Loading auth state');
		}
	}, [isLoading]);

	useLayoutEffect(() => {
		checkAuthState();
	}, []);

	if (isLoading || isLoadingAnimation) {
		return (
			<>
				<LoadingAnimated />
			</>
		);
	}
	return <>{children}</>;
};
