import { FC } from 'react';

interface SidebarLogoTypes {
	src: string;
	open: boolean;
}

export const SidebarLogo: FC<SidebarLogoTypes> = ({ src, open }) => {
	return (
		<img
			src='/img/LolaLogo.png'
			className={`cursor-pointer duration-200 w-full ${open && 'w-9/12'}`}
		/>
	);
};
