import { BellIcon } from '@heroicons/react/solid';
import { FC } from 'react';

interface NotificationsIconTypes {
	hasNewNotification: boolean;
}

export const NotificationsIcon: FC<NotificationsIconTypes> = ({
	hasNewNotification,
}) => {
	return (
		<>
			<BellIcon className='w-8 text-light-primary-greenDark hover:text-light-primary-gray duration-200 cursor-pointer' />
			{hasNewNotification && (
				<div className='w-2 h-2 absolute top-1 right-1 rounded-full bg-light-primary-green' />
			)}
		</>
	);
};
