import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { getAppMenu } from 'config/menu';
import { menuStructure } from 'config/menu/structure';
import { AppRoute } from 'domain/routes/route.type';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthState } from 'redux/slices/authentication/selectors';
import { setBreadCrumb } from 'redux/slices/breadcrumb';
import { selectBreadCrumbState } from 'redux/slices/breadcrumb/selectors';
import { selectEnterpriseState } from 'redux/slices/enterprise/selectors';
import { RenderIf } from 'utils/RenderIf';
import { SidebarLogo } from '../../atoms/Sidebar/SidebarLogo';
import { SidebarOpenButton } from '../../atoms/Sidebar/SidebarOpenButton';
import { SidebarItem } from '../../molecules/SidebarItem';

export const SidebarMenu: FC = () => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(true);
	const { breadcrumbs } = useSelector(selectBreadCrumbState);
	const { userIsAuthenticated, permissions } = useSelector(selectAuthState);
	const enterprise = useSelector(selectEnterpriseState);
	const [appMenus, setAppMenus] = useState<AppRoute[]>([]);
	const updateBreadCrumb = useCallback(
		(breadcrumb: string[]) => {
			dispatch(setBreadCrumb(breadcrumb));
		},
		[dispatch]
	);

	useEffect(() => {
		if (userIsAuthenticated) {
			const menus = getAppMenu(permissions, enterprise);
			setAppMenus(menus);
		} else {
			setAppMenus([]);
		}
	}, [permissions, userIsAuthenticated]);

	useEffect(() => {
		/* console.log({ appMenus }); */
	}, [appMenus]);

	return (
		<div
			className={` ${
				open ? 'w-64' : 'w-32 '
			} h-screen  p-5 pt-8 relative  duration-300 rounded-xl overflow-hidden sticky top-0 `}
		>
			<SidebarOpenButton open={open} setOpen={setOpen} />
			<div
				className={` ${
					open ? 'w-48' : 'w-20 '
				} bg-light-primary-darkGreen h-full drop-shadow-xl overflow-hidden relative  duration-300 rounded-xl mb-24`}
			>
				<div className='flex gap-x-4 items-center pt-8 px-4'>
					<SidebarLogo src='/img/LolaLogo.png' open={open} />
				</div>
				<div className='h-5/6 absolute top-13 w-full overflow-y-auto px-4 pb-8'>
					<ul className='pt-6 text-white'>
						<RenderIf
							isTrue={
								userIsAuthenticated && appMenus?.length > 0 && Boolean(enterprise._id)
							}
						>
							{Object.keys(menuStructure).map((parentMenu: string, index) => {
								if (typeof menuStructure[parentMenu] === 'string') {
									return (
										<SidebarItem
											key={menuStructure[parentMenu]} // id
											gap={false} // @TODO
											open={open}
											route={
												appMenus?.find(
													route => route.id === menuStructure[parentMenu]
												) as AppRoute
											}
											setBreadCrumb={updateBreadCrumb}
										/>
									);
								}
								if (Array.isArray(menuStructure[parentMenu])) {
									return (
										<Dopwdown
											id={menuStructure[parentMenu]}
											label={parentMenu}
											items={appMenus?.filter(route =>
												menuStructure[parentMenu].includes(route.id)
											)}
											openMenu={open}
											updateBreadCrumb={updateBreadCrumb}
											key={menuStructure[parentMenu]}
										/>
									);
								}
							})}
						</RenderIf>
					</ul>
				</div>
			</div>
		</div>
	);
};

interface Props {
	label: string;
	id: string;
	items: AppRoute[];
	openMenu: boolean;
	updateBreadCrumb: any;
}

const Dopwdown = ({ label, id, items, openMenu, updateBreadCrumb }: Props) => {
	return (
		<>
			<Disclosure defaultOpen>
				{({ open }) => (
					<>
						<Disclosure.Button
							id={id}
							className={`flex w-full justify-between rounded-lg mt-2 px-1 ${
								!openMenu ? 'p-1' : 'pl-3'
							}  py-2 text-left text-sm font-medium text-white hover:bg-light-primary-transparentGreen duration-200 focus:outline-none`}
						>
							<span className={`${!openMenu && 'hidden'} origin-left duration-200 `}>
								{label}
							</span>
							<ChevronUpIcon
								className={`${open ? 'rotate-180 transform' : ''} ${
									!openMenu && 'hidden'
								} h-5 w-5 text-light-primary-green`}
							/>
							{!openMenu && <p className='text-center w-full'>--</p>}
						</Disclosure.Button>
						<Disclosure.Panel className=' pb-2 text-s'>
							{items.map((route, index) => (
								<SidebarItem
									key={route.id}
									gap={false} // @TODO
									open={openMenu}
									route={route}
									setBreadCrumb={updateBreadCrumb}
								/>
							))}
						</Disclosure.Panel>
					</>
				)}
			</Disclosure>
		</>
	);
};
