/**
 * @description Common Layout with Header.
 */
import { useNavigate } from 'react-router-dom';
import { SidebarMenu } from '../components/organisms/SidebarMenu';
import { useSelector, useDispatch } from 'react-redux';
import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { clearAuthState } from '../redux/slices/authentication';
import { clearEnterpiseState } from '../redux/slices/enterprise';
import { selectAuthState } from '../redux/slices/authentication/selectors';
import { selectEnterpriseState } from '../redux/slices/enterprise/selectors';
import { Navbar } from '../components/organisms/Navbar';

export const CommonPageLayout: FC<PropsWithChildren<any>> = ({ children }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { userIsAuthenticated } = useSelector(selectAuthState);
	const { logo } = useSelector(selectEnterpriseState);
	const onLogout = useCallback(() => {
		dispatch(clearAuthState());
		dispatch(clearEnterpiseState());
		localStorage.clear();
		navigate('/');
	}, [dispatch]);

	return (
		<div className='flex bg-light-primary-white' id='principalLayout'>
			<SidebarMenu />

			<div className='min-h-screen flex-1 p-2 pt-8 pr-6'>
				<Navbar onLogout={onLogout} logo={logo} />
				{children}
			</div>
		</div>
	);
};
