import { createSlice } from '@reduxjs/toolkit';
import { IClientsSlice } from './type';

export const initialState: IClientsSlice = {
	clients: [],
};

export const ClienntsSlice = createSlice({
	name: 'clients',
	initialState,
	reducers: {
		clearAuthState: () => initialState,
		authenticateUser: (state, action) => ({
			...state,
			userIsAuthenticated: true,
			...action.payload,
		}),
	},
});

export default ClienntsSlice.reducer;
export const { clearAuthState, authenticateUser } = ClienntsSlice.actions;