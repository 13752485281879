import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseConfig } from '../../../config/http';

export const modulesAPI = createApi({
	reducerPath: 'modules-api',
	baseQuery: fetchBaseQuery({
		...baseConfig,
	}),
	endpoints: builder => ({
		getAppModules: builder.query({
			query: () => ({
				url: '/api/modules',
				method: 'GET',
			}),
		}),
	}),
});

export const { useGetAppModulesQuery } = modulesAPI as any;
