import { ArrowsExpandIcon } from '@heroicons/react/outline';

export const ButtonFullScreen = () => {
	const handleFullScreenWindow = () => {
		var elem = document.getElementById('principalLayout');
		if (elem?.requestFullscreen) {
			elem.requestFullscreen();
		}
	};

	return (
		<ArrowsExpandIcon
			className='w-8 text-light-primary-greenDark hover:text-light-primary-gray duration-200 cursor-pointer'
			onClick={handleFullScreenWindow}
		/>
	);
};
