import { FC, ReactNode } from 'react';

interface SidebarIconsTypes {
	icon: ReactNode;
}

export const SidebarIcon: FC<SidebarIconsTypes> = ({ icon }) => {
	return (
		<div className='p-1 bg-light-primary-greenDark hover:bg-light-primary-transparentGreen  duration-200 rounded-sm'>
			{icon}
		</div>
	);
};
