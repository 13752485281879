import { combineReducers } from '@reduxjs/toolkit';
import { authenticationApi } from '../../redux/apis/authentication';
import { clientsApi } from '../../redux/apis/clients';
import BreadCrumbSlice from 'redux/slices/breadcrumb';
import AuthenticationSlice from '../../redux/slices/authentication';
import ClientsSlice from '../../redux/slices/clients';
import EnterpriseSlice from 'redux/slices/enterprise';
import EnterprisesSlice from 'redux/slices/enterprises';
import { enterprisesApi } from 'redux/apis/enterprises';
import { modulesAPI } from 'redux/apis/modules';
import { usersApi } from 'redux/apis/users';
import { publiappApi } from 'redux/apis/publiApp';

export const rootReducer = combineReducers({
	[authenticationApi.reducerPath]: authenticationApi.reducer,
	[clientsApi.reducerPath]: clientsApi.reducer,
	[enterprisesApi.reducerPath]: enterprisesApi.reducer,
	[modulesAPI.reducerPath]: modulesAPI.reducer,
	[usersApi.reducerPath]: usersApi.reducer,
	[publiappApi.reducerPath]: publiappApi.reducer,

	auth: AuthenticationSlice,
	breadCrumb: BreadCrumbSlice,
	clients: ClientsSlice,
	enterprise: EnterpriseSlice,
	enterprises: EnterprisesSlice,
});
