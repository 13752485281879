import { OfficeBuildingIcon } from '@heroicons/react/solid';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const BussinesListPage = lazy(() => import('../pages/BussinesList'));
const EditBussinesPage = lazy(() => import('../pages/EditBussines'));
const AddBussinesPage = lazy(() => import('../pages/AddBussines'));

export const routes: AppRoute[] = [
	{
		id: 'BussinesList',
		name: 'Bussines',
		path: '/bussines',
		icon: <OfficeBuildingIcon className={'text-light-primary-green w-6 h-6'} />,
		page: BussinesListPage,
		breadcrumb: ['Bussines'],
		showInMenu: false,
		isPrivate: true,
	},
	{
		id: 'EditBussines',
		name: 'Edit Bussines',
		path: '/bussines/edit',
		icon: <OfficeBuildingIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditBussinesPage,
		breadcrumb: ['Bussines', 'Edit bussines'],
		isPrivate: true,
	},
	{
		id: 'AddBussines',
		name: 'Add Bussines',
		path: '/bussines/add',
		icon: <OfficeBuildingIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddBussinesPage,
		breadcrumb: ['Bussines', 'Add Bussines'],
		isPrivate: true,
	},
];
