import { ChevronLeftIcon } from '@heroicons/react/solid';
import { FC } from 'react';

interface SidebarOpenButtonTypes {
	open: boolean;
	setOpen: (open: boolean) => void;
}

export const SidebarOpenButton: FC<SidebarOpenButtonTypes> = ({
	open,
	setOpen,
}) => {
	return (
		<ChevronLeftIcon
			className={`text-light-primary-darkGreen bg-light-primary-green absolute cursor-pointer z-10 right-[25px] top-12 w-7 
			 			 rounded-full  ${!open && 'rotate-180 right-[15px]'}`}
			onClick={() => setOpen(!open)}
		/>
	);
};
