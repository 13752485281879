import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { Enterprise } from 'redux/slices/enterprises/type';
import { baseConfig } from '../../../config/http';

// We can move endpoint to another files
export const enterprisesApi = createApi({
	reducerPath: 'enterprices-api',
	baseQuery: fetchBaseQuery({
		...baseConfig,
	}),
	endpoints: builder => ({
		getEnterprises: builder.mutation({
			query: () => ({
				url: `/api/enterprises`,
				method: 'GET',
			}),
		}),
		getEnterpriseById: builder.mutation({
			query: (id: string) => ({
				url: `/api/enterprises/${id}`,
				method: 'GET',
			}),
		}),
		updateEnterprise: builder.mutation({
			query: (enterprice: Enterprise) => ({
				url: `/api/enterprises/`,
				method: 'PATCH',
				body: enterprice,
			}),
		}),
		createEnterprise: builder.mutation({
			query: (enterprice: Enterprise) => ({
				url: `/api/enterprises/`,
				method: 'POST',
				body: enterprice,
			}),
		}),
		getCurrentEnterprise: builder.mutation({
			query: (id: string) => ({
				url: `/api/enterprise/current`,
				method: 'GET',
			}),
		}),
		uploadFilesEnterprise: builder.mutation({
			query: (file: any) => ({
				url: `/api/enterprise/uploadfile`,
				method: 'POST',
				body: file,	
			})
		})
	}),
});

export const {
    useGetEnterprisesMutation,
    useGetEnterpriseByIdMutation,
    useUpdateEnterpriseMutation,
    useCreateEnterpriseMutation,
	useGetCurrentEnterpriseMutation,
	useUploadFilesEnterpriseMutation
} = enterprisesApi as any;
