import { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { configSettingsMenu } from './configSettingMenu';
import { selectAuthState } from '../../../redux/slices/authentication/selectors';
import { CogIcon } from '@heroicons/react/solid';

interface DropdownSettingsTypes {
}

export const DropdownSettings: FC<DropdownSettingsTypes> = ({ }) => {
	const { name, email } = useSelector(selectAuthState);
	return (
		<>
			<Menu as='div' className='relative inline-block text-left '>
				<div className='flex justify-center items-center'>
					<Menu.Button className='relative flex flex-row items-center justify-start'>
						<CogIcon className="h-8 w-8"/>
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter='transition ease-out duration-100'
					enterFrom='transform opacity-0 scale-95'
					enterTo='transform opacity-100 scale-100'
					leave='transition ease-in duration-75'
					leaveFrom='transform opacity-100 scale-100'
					leaveTo='transform opacity-0 scale-95'
				>
					<Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
						{configSettingsMenu.map((data, index) => (
							<div key={data.title} className={`${'text-gray-900'} group flex items-start rounded-md px-2 py-2 text-sm flex-col w-full duration-200 cursor-pointer`}>
								<Link to={data.link} className='flex items-center px-1 py-1 w-full'>
									<h3>{data.title}</h3>
								</Link>
							</div>
						))}
					</Menu.Items>
				</Transition>
			</Menu>
		</>
	);
};
