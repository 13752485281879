import { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { configUserMenu } from './configUserMenu';
import { NotificationsItem } from '../../atoms/Notifications/NotificationsItem';
import { selectAuthState } from '../../../redux/slices/authentication/selectors';
import { Link, useNavigate } from 'react-router-dom';

interface DropdownUserTypes {
	onLogout: () => void;
	enterpriseLogo?: string;
}

export const DropdownUser: FC<DropdownUserTypes> = ({
	onLogout,
	enterpriseLogo,
}) => {
	const { name, email } = useSelector(selectAuthState);
	const navigate = useNavigate();

	const setToURL = (url: string) => {
		navigate(url);
	};

	return (
		<>
			<Menu as='div' className='relative inline-block text-left '>
				<div className='flex justify-center items-center'>
					<Menu.Button className='relative flex flex-row items-center justify-start'>
						<img
							className='_profileImage w-9 h-9 border rounded-full object-cover'
							src={
								enterpriseLogo ||
								'https://lolagestion.com/wp-content/uploads/2022/05/cropped-Favi_Insta-192x192.png'
							}
						/>
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter='transition ease-out duration-100'
					enterFrom='transform opacity-0 scale-95'
					enterTo='transform opacity-100 scale-100'
					leave='transition ease-in duration-75'
					leaveFrom='transform opacity-100 scale-100'
					leaveTo='transform opacity-0 scale-95'
				>
					<Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
						<div className='flex flex-col mb-2 p-3'>
							<h1 className='font-bold text-sm'> {name}</h1>
							<p className='font-light text-xs'> {email}</p>
						</div>
						{configUserMenu.map((data: any, index: number) => (
							<div key={data.title}>
								{data.title === 'Logout' ? (
									<button className='px-1 py-1 w-full' onClick={onLogout}>
										<NotificationsItem title={data.title} />
									</button>
								) : (
									<button
										className='px-1 py-1 w-full'
										onClick={() => setToURL(data?.url ? data?.url : '')}
									>
										<NotificationsItem title={data.title} />
									</button>
								)}
							</div>
						))}
					</Menu.Items>
				</Transition>
			</Menu>
		</>
	);
};
